<template>
  <div class="inline-block">
    {{ this.awardData }}
  </div>
</template>

<script>
export default {
  props: ["awardData"],
};
</script>
