var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(1 === 2)?_c('div',[_c('label',{staticClass:"block text-1xl text-semibold mb-4 max-w-screen-sm text-gray-400"},[_vm._v(" To view the portfolio please enter the password ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.enteredPassword),expression:"enteredPassword"}],staticClass:"px-6 py-3 mb-4 mr-4 rounded-md text-black bg-white focus:outline-none",attrs:{"type":"text","placeholder":"Password"},domProps:{"value":(_vm.enteredPassword)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.processPassword.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.enteredPassword=$event.target.value}}}),_c('button',{staticClass:"px-6 py-3 mb-4 rounded-md text-black bg-white opacity-30 hover:opacity-100 focus:outline-none",on:{"click":_vm.processPassword}},[_vm._v(" Submit ")]),(_vm.passwordWrong)?_c('div',{staticClass:"italic text-gray-500 text-sm mr-2"},[_vm._v(" Sorry that is not the password, please try again. ")]):_vm._e()]):_vm._e(),_c('div',[_c('div',[_c('hr',{staticClass:"mb-4 opacity-20"}),_c('div',{staticClass:"text-gray-400 font-normal mb-1"},[_vm._v("Retailer.Space")]),_c('div',{staticClass:"text-gray-50 font-semibold text-lg mb-2"},[_vm._v(" Commercial Real Estate Brokerage Platform ")]),_c('div',{staticClass:"text-gray-400 mb-4 max-w-screen-md"},[_vm._v(" Retailer.Space is a broker, broker team, and client collaboration tool for commercial real estate. The retailer.space platform allows you to collect data in a centralized place to discover market insights, share listings, and collaborate with your team and clients. ")]),_c('div',{staticClass:"flex flex-wrap mb-8"},[_c('a',{staticClass:"mr-2",attrs:{"target":"_blank","href":"https://retailer.space/"}},[_c('div',{staticClass:"bg-cover bg-center w-44 h-44 m-2 shadow-lg rounded-md",style:({
              backgroundImage: 'url(/port/retailerspace-01_thmb.jpg)'
            })})]),_c('a',{staticClass:"mr-2",attrs:{"target":"_blank","href":"https://www.figma.com/proto/zYYrdNVAnmBhRvLRL1c4aI/Retailer.Space-v3?node-id=3%3A726&viewport=1144%2C444%2C0.03&scaling=contain&starting-point-node-id=244%3A7090"}},[_c('div',{staticClass:"bg-cover bg-left-top w-44 h-44 m-2 shadow-lg rounded-md",style:({
              backgroundImage: 'url(/port/retailerspace-02_thmb.jpg)'
            })})]),_c('a',{staticClass:"mr-2",attrs:{"target":"_blank","href":"https://www.figma.com/proto/zYYrdNVAnmBhRvLRL1c4aI/Retailer.Space-v3?node-id=3%3A726&viewport=1144%2C444%2C0.03&scaling=contain&starting-point-node-id=244%3A7115"}},[_c('div',{staticClass:"bg-cover bg-left-top w-44 h-44 m-2 shadow-lg rounded-md",style:({
              backgroundImage: 'url(/port/retailerspace-03_thmb.jpg)'
            })})])])]),_vm._l((_vm.projectListMain),function(project,i){return _c('project',{key:'a' + i,staticClass:"h-full",attrs:{"projectData":project}})}),_vm._l((_vm.projectListSecondary),function(project,i){return _c('project',{key:'b' + i,staticClass:"h-full",attrs:{"projectData":project}})})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }